import { DEFAULT_ARTIST_IMAGES } from 'config/constants';
import { ArtistProfile } from 'types/apiv2';
import { DIGITAL_SERVICE_PROVIDERS } from 'types/constants';

/**
 * [getDefaultArtistImage] - repeatably assign default image url based on character code
 * @param {string} name - uses name of artist to create an integer which is used to select the default image url
 * @returns {string} default image url
 */

export const getDefaultArtistImage = (name: string) => {
  const codeSum = name.split('').reduce((accum, character) => {
    const charCode = character.charCodeAt(0);
    const code = Number.isNaN(charCode) ? 0 : charCode;
    return accum + code;
  }, 0);

  return DEFAULT_ARTIST_IMAGES[codeSum % DEFAULT_ARTIST_IMAGES.length];
};

/**
 * [isArtistSoundCloudBypassEnabled] - checks the artist profile for the artist_config that allows artist
 * soundcloud verification step to be skipped in various artist profile wizard contexts.
 *
 * @param {object} artistProfile
 * @returns {boolean}
 */

export const isArtistSoundCloudBypassEnabled = (artistProfile: ArtistProfile) =>
  artistProfile?.artist_configs?.find(
    ({ config_key, config_value }) =>
      config_key === 'skip_soundcloud_verification' && config_value === true
  );

/**
 * [getArtistDspConnection] - checks the artist profile connections for a dsp connection matching the passed
 * service.
 */

export const getArtistDspConnection = ({
  artistProfile,
  service
}: {
  artistProfile: ArtistProfile;
  service: keyof typeof DIGITAL_SERVICE_PROVIDERS;
}) => artistProfile.connections.find(({ dsp }) => dsp === service);
