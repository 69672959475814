import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import Button, { ButtonGroup } from 'components/Button';
import { displayAlertError } from 'helpers/alert';

import { generateSelectorsFromTestIds } from 'helpers';
import ActionMenuList from '../ActionMenuList';

const ConfirmationContent = styled.div`
  padding: 0.75rem 1.5rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 0;
`;

export interface ConfirmationActionMenuConfig {
  cancelText?: string; // text to use in the cancel button
  confirmText?: string; // text to use in the confirm button
  // [handleConfirm] if true, the confirm button will be disabled until the [onConfirm] function resolves
  handleConfirm?: boolean;
  message: string | React.ReactNode; // displayed above confirm & cancel buttons.
  // [onConfirm] - function to call when the confirm button is clicked.  If [handleConfirm] is true, the confirm
  // button will be disabled until the onConfirm function resolves and onConfirm must return a promise.
  // TODO: We should refactor this component so that this argument isn't so complicated
  onConfirm: any;
  onCancel?: ({ close }) => void; // if nothing passed, will default to using close method, else it's up to you.
}

interface ActionMenuToggleState {
  isOpen: boolean; // whether or not the confirmation menu is open
  close: () => void; // closes the confirmation menu
  closeAll?: () => void; // closes the confirmation menu and parent menu if one exists
  closeOnNavigation?: boolean;
}

type ConfirmationActionMenuProps = ActionMenuToggleState & ConfirmationActionMenuConfig;

/**
 * [ConfirmationActionMenu] - Action menu displayed to confirm an action. Can be used on desktop
 * and will be rendered as a modal.
 */

const ConfirmationActionMenu = ({
  cancelText,
  confirmText,
  handleConfirm,
  message,
  onConfirm,
  onCancel,
  // action menu props:
  isOpen,
  close,
  closeOnNavigation,
  closeAll
}: ConfirmationActionMenuProps) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleOnConfirm = useCallback(() => {
    setSubmitting(true);

    const onConfirmProps = {
      close,
      closeAll,
      setSubmitting
    };

    if (handleConfirm) {
      return onConfirm()
        .then(() => {
          if (closeAll) {
            closeAll();
          } else {
            close();
          }
        })
        .catch(e => {
          setSubmitting(false);
          displayAlertError(e);
        });
    }

    return onConfirm(onConfirmProps);
  }, [onConfirm, close, closeAll, handleConfirm]);

  // ensure submitting state reset to false when closed
  useEffect(() => {
    if (!isOpen && isSubmitting) setSubmitting(false);
  }, [isOpen, isSubmitting]);

  const handleOnCancel = useCallback(() => {
    // if onCancel method passed, give close method as an argument
    if (onCancel) {
      onCancel({ close });
      return;
    }

    close();
  }, [onCancel, close]);

  return (
    <ActionMenu
      isOpen={isOpen}
      close={close}
      closeOnNavigation={closeOnNavigation}
      disabled={isSubmitting}
      showCancel={false}
    >
      <ActionMenuList>
        <ConfirmationContent>
          <div>{message}</div>
          <StyledButtonGroup>
            <Button
              type={Button.COLORS.NEUTRAL}
              disabled={isSubmitting}
              onClick={handleOnCancel}
              data-testid={ConfirmationActionMenu.TEST_IDS.CANCEL}
            >
              {cancelText || 'Cancel'}
            </Button>
            <Button
              type={Button.COLORS.PRIMARY}
              disabled={isSubmitting}
              onClick={handleOnConfirm}
              data-testid={ConfirmationActionMenu.TEST_IDS.CONFIRM}
            >
              {confirmText || 'Yes'}
            </Button>
          </StyledButtonGroup>
        </ConfirmationContent>
      </ActionMenuList>
    </ActionMenu>
  );
};

ConfirmationActionMenu.TEST_IDS = {
  CONFIRM: 'ActionMenuConfirm-confirm',
  CANCEL: 'ActionMenuConfirm-cancel'
};

ConfirmationActionMenu.SELECTORS = generateSelectorsFromTestIds(
  ConfirmationActionMenu.TEST_IDS
);

export default ConfirmationActionMenu;
