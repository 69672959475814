// Single source for mapping for all app routes
const routeMap = {
  account: {
    root: '/account',
    admin: '/admin',
    basics: '/basic-info',
    payment: '/payment',
    connections: '/connections',
    notifications: '/notifications',
    proAccess: '/pro-access',
    downloads: '/downloads',
    subscriptions: '/subscriptions',
    discoveryMode: '/discovery-mode'
  },
  admin: {
    youtubeUploader: '/youtube-uploader'
  },
  artists: {
    root: '/artists/:id/:name',
    mixes: '/mixes',
    tracks: '/tracks',
    details: '/details',
    feedback: {
      root: '/feedback',
      rootWithParams: '/feedback/:type',
      given: '/given',
      received: '/received'
    },
    settings: {
      root: '/settings',
      notifications: '/notifications',
      labels: '/labels',
      shows: '/shows',
      connections: '/connections',
      admin: '/admin',
      basics: '/basics'
    }
  },
  artistProfileWizard: {
    base: '/artist-profile-editor',
    root: '/artist-profile-editor/:context/:stepId',
    indexRoot: '/artist-profile-editor/:context', // used for handling redirects to default path
    getRootPathForContext: context => `${routeMap.artistProfileWizard.base}/${context}`,
    getOverviewPathForContext: context =>
      `${routeMap.artistProfileWizard.base}/${context}/overview`,
    getStepPathForContext: (context, stepId) =>
      `${routeMap.artistProfileWizard.base}/${context}/${stepId}`
  },
  authentication: {
    createAccount: '/create-account',
    signIn: '/sign-in',
    forgotPassword: '/forgot-password'
  },
  collections: {
    root: '/collections/:id/:collectionName',
    packs: '/packs',
    users: '/users',
    labels: '/labels'
  },
  confirmation: '/confirmation',
  contracts: {
    root: '/contracts/:id',
    confirmation: '/confirmation'
  },
  discoveryMode: '/discovery-mode',
  external: {
    compoundArtists:
      'https://intercom.help/proton-radio/en/articles/5345252-compound-artists-collaborations',
    help: {
      newArtistProfile:
        'https://intercom.help/proton-radio/en/articles/5395694-setting-up-new-artist-profiles-on-proton',
      wrongSoundCloudProfile:
        'https://intercom.help/proton-radio/en/articles/5986403-verification-support-incorrect-soundcloud',
      statementsAndPaymentsSent:
        'https://intercom.help/proton-radio/en/articles/2199671-when-are-statements-and-payments-sent',
      spotifyPopularityIndex:
        'https://medium.com/protonradio/introducing-spotify-popularity-scores-know-which-artists-can-help-you-grow-879cbf78fb98',
      discoverModeExplained: '#' // TODO: add this article
    },
    hiring: 'https://protonrad.io/hiring',
    mixDemoSubmit:
      'https://intercom.help/proton-radio/proton-radio/how-do-i-get-a-mix-or-radio-show-on-proton',
    promoteReleaseDjMix:
      'https://intercom.help/proton-radio/en/articles/4352334-how-to-promote-your-new-release-with-a-dj-mix-on-spotify-apple-music',
    soundSystem: {
      home: 'https://soundsystem.protonradio.com/',
      labels: 'https://soundsystem.protonradio.com/main.php?tab=labels'
    },
    support: 'https://intercom.help/proton-radio',
    trackDemoSubmit:
      'https://intercom.help/proton-radio/soundsystem/how-can-i-submit-unsigned-demos-to-protons-labels'
  },
  inbox: {
    root: '/inbox',
    subscriptions: '/subscriptions',
    promos: '/promos',
    welcome: '/welcome'
  },
  labels: {
    root: '/labels/:id/:name', // should default to /subscribers
    tracks: '/tracks',
    artists: '/artists',
    details: '/details',
    releases: '/releases',
    feedback: '/feedback',
    subscribers: '/subscribers',
    subscribersAdd: '/subscribers/add',
    soundCloudVerify: '/soundcloud-verification/artists/:artistId'
  },
  packs: {
    root: '/packs/:id/:packName',
    tracks: '/tracks',
    review: '/review'
  },
  privacyPolicy: '/privacy-policy',
  releases: {
    root: '/releases/:id/:name',
    tracks: '/tracks',
    feedback: '/feedback',
    details: '/details',
    share: '/share'
  },
  search: '/search',
  shows: {
    root: '/shows/:id/:name',
    details: '/details'
  },
  top100: {
    mixes: '/top-100/mixes'
  },
  users: {
    root: '/users/:id',
    verification: '/verification'
  },
  radio: '/proton-radio'
} as const;

export default routeMap;
