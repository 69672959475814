import React, { ReactNode } from 'react';
import { CombinedError } from 'urql';

import { GraphQLError } from 'graphql';
import { GqlApiError } from 'graphql/types';
import { ReplaceTypeOfKey } from 'types';
import { User } from '../useCurrentUser';

type SomeError = { message: string };
export type CombinedErrorDisplay = ReplaceTypeOfKey<CombinedError, 'message', ReactNode>;

export const isSkipped = (
  err: GraphQLError | GqlApiError,
  skip?: { [key: string]: string }
): boolean => {
  if (!err.path) return false;
  for (const key of err.path) {
    if (skip && skip[key] === err.message) return true;
  }
  return false;
};

// NOTE: for `useCurrentUser` hook ONLY
export const isProUser = (user: User) =>
  user && (user.roles.labelManager || user.roles.artist);

export const showIntercomChatWithErrors = (errors: SomeError[]) => {
  window.Intercom(
    'showNewMessage',
    `Hey Proton team! I'm getting an alert that an unexpected error occurred while trying to update info. ` +
      `For reference the error has the following message(s):\n\n` +
      `${errors.map(e => e.message).join('\n')}\n\n` +
      `Can you help look into this for me? Thanks so much. 🙏`
  );
};

export const unexpectedErrorMessage = (
  isPro: boolean,
  errors: SomeError[]
): ReactNode => (
  <>
    {'Sorry, an unexpected error occurred! Try again in a minute or '}
    {isPro ? (
      <button
        aria-label="Contact support about this error"
        style={{
          background: 'none',
          border: 'none',
          padding: '0',
          font: 'inherit',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        data-testid="contact-support-button"
        onClick={() => showIntercomChatWithErrors(errors)}
      >
        <strong>contact support</strong>
      </button>
    ) : (
      'contact support@protonradio.com for help'
    )}
    .
  </>
);
