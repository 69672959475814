import { useEffect } from 'react';

import { LAST_POLICY_CONSENT_STORAGE_KEY } from 'config/constants';
import { GetCurrentUserQuery } from 'gql/graphql';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useCurrentUser from 'hooks/useCurrentUser';
import useEffectOnInitialUserLoad from 'hooks/useEffectOnInitialUserLoad';
import { addAlertMessage, removeAlertMessage } from 'redux/actions/ui';

import { graphql } from 'gql';
import { logError } from 'helpers';
import { ElementOf } from 'types';

const PRIVACY_POLICY_VERSION = process.env.REACT_APP_PRIVACY_POLICY_VERSION!;
export const PrivacyPolicyConsentMutation = graphql(`
  mutation privacyPolicyConsent($version: ID!) {
    privacyPolicyConsent(input: { version: $version }) {
      privacyPolicyConsents {
        id
        version
      }
      errors
    }
  }
`);

type PrivacyPolicyConsent = ElementOf<
  NonNullable<GetCurrentUserQuery['viewer']>['privacyPolicyConsents']
>;
type LocalPrivacyPolicyConsent = Pick<PrivacyPolicyConsent, 'version'>;
type AnyConsent = PrivacyPolicyConsent | LocalPrivacyPolicyConsent;

/**
 * [usePrivacyPolicyConsent] this hook handles managing the privacy policy consent for the user. To
 * do this, it does the following:
 *
 * 1) Checks if the user has consented to the latest privacy policy version and if not, shows the
 *    privacy policy consent alert.
 * 2) When we load the user for the first time we double check their consented privcacy policy versions
 *    to make determine if we need to add a new consent record.
 */

function usePrivacyPolicyConsent() {
  const dispatch = useAppDispatch();
  const { user: currentUser, fetching } = useCurrentUser();

  const jwt = useAppSelector(state => state?.token?.jwt);
  // In the case of the privacy policy check, we might be loading user data and we don't want to perform the
  // privacy policy check until we have the user data.
  const initialLoadComplete = (!fetching && !!jwt && currentUser.id) || !jwt;

  // 1) Check if the user has consented to the latest privacy policy version
  useEffect(() => {
    if (!initialLoadComplete) return;

    // NOTE: in case a user accepts the privacy policy when signed out, we save the last stored consent in local storage
    const lastConsent = localStorage.getItem(LAST_POLICY_CONSENT_STORAGE_KEY);
    const allConsents: AnyConsent[] = [...currentUser.privacyPolicyConsents];
    if (lastConsent) allConsents.push({ version: lastConsent });

    if (
      PRIVACY_POLICY_VERSION &&
      !allConsents.find(({ version }) => version === PRIVACY_POLICY_VERSION)
    ) {
      dispatch(
        addAlertMessage({
          component: 'PrivacyPolicyConsent',
          timeout: false
        })
      );
    } else {
      dispatch(removeAlertMessage({ id: 'PrivacyPolicyConsent' }));
    }
  }, [currentUser, dispatch, initialLoadComplete]);

  // 2) When we load the user for the first time we want to see if they have consented to the latest
  // privacy policy before signing in and if so, save that consent record.
  const [, consentToPrivacyPolicy] = useMutationWithAlert(PrivacyPolicyConsentMutation);
  useEffectOnInitialUserLoad(
    user => {
      const lastConsent = window.localStorage.getItem(LAST_POLICY_CONSENT_STORAGE_KEY);
      const userConsents = user.privacyPolicyConsents;

      if (lastConsent && !userConsents.find(({ version }) => version === lastConsent)) {
        consentToPrivacyPolicy({ version: PRIVACY_POLICY_VERSION }).catch(logError);
      }
    },
    [consentToPrivacyPolicy]
  );
}

export default usePrivacyPolicyConsent;
