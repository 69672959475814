import Modal from 'components/Modal';
import { EVENTS, ProtonEventEmitter } from 'config/events';
import { GqlApiError } from 'graphql/types';
import { logError } from 'helpers/logError';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { useAppDispatch } from 'hooks/redux';
import useCurrentUser from 'hooks/useCurrentUser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { tokenHandler } from 'redux/actions/token';
import { getUser } from 'redux/actions/user';

const AuthenticateWithMagicLinkMutation = `
  mutation AuthenticateWithMagicLink($token: ID!) {
    authenticateWithMagicLink (input: { token: $token }) {
      errorDetails {
        message
        path
      }
      token # this is a token that can be used to request a new magic link if expired 
      user {
        id
        token
        refreshToken
      }
    }
  }
`;

type AuthenticateWithMagicLinkData = {
  authenticateWithMagicLink: {
    token: string;
    user: {
      id: number;
      token: string;
      refreshToken: string;
    };
    errorDetails: GqlApiError[];
  };
};

/**
 * [MagicLinkVerificationModal] - This modal watches for `verificationToken` in the url that is passed in from
 * the MFA verification email. The token is used to authenticate the current session. The user may even be
 * logged in already and need "sudo" access to perform the action.
 */

const MagicLinkVerificationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const dispatch = useAppDispatch();
  const { user: currentUser } = useCurrentUser();

  const { search } = useLocation();
  const history = useHistory();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const verificationToken = urlParams.get('verificationToken');
  const redirectUrl = urlParams.get('redirectUrl');

  const [{ hasErrors }, verifyMagicToken] =
    useMutationWithAlert<AuthenticateWithMagicLinkData>(
      AuthenticateWithMagicLinkMutation,
      { token: 'invalid' }
    );

  const clearParamsFromHistory = () => {
    urlParams.delete('verificationToken');
    urlParams.delete('redirectUrl');
    // they can retrigger by following the link in their email again.
    history.replace({ search: urlParams.toString() });
  };

  const closeModal = () => {
    clearParamsFromHistory();

    setIsOpen(false);
  };

  if (success && redirectUrl) {
    setTimeout(() => {
      closeModal();
      window.location.href = redirectUrl;
    }, 2000);
  }

  const authenticateOrRequireMFA = useCallback(
    async (token: string) => {
      const { data, dataErrors } = await verifyMagicToken({ token });

      const invalidToken = !!dataErrors?.find(
        ({ message, path }) => path?.includes('token') && message === 'invalid'
      );
      if (invalidToken) {
        const requestToken = data!.authenticateWithMagicLink.token;
        // If the token has expired, we need to request a new magic link.
        ProtonEventEmitter.emit(EVENTS.MFA_REQUIRED, requestToken, invalidToken);
        setIsOpen(false);
        return;
      }

      const user = data?.authenticateWithMagicLink.user;
      if (user) {
        // Handle login user if they are not already logged in
        if (!currentUser.id) {
          const { token, refreshToken } = user;
          tokenHandler(dispatch)({ jwt: token, refresh_token: refreshToken });

          await dispatch(getUser(user.id));
        }
        setSuccess(true);
      }
    },
    [currentUser.id, dispatch, verifyMagicToken]
  );

  useEffect(() => {
    if (verificationToken) {
      setIsOpen(true);

      authenticateOrRequireMFA(verificationToken).catch(logError);
    }
  }, [verificationToken, authenticateOrRequireMFA]);

  if (!isOpen) return null;

  if (success) {
    return (
      <Modal
        title="Identity Verified!"
        subtitle={
          <>
            Thank you for confirming your identity.{' '}
            {redirectUrl
              ? "In a second you'll be returned to the page where you left off!"
              : 'You may now close this window.'}
          </>
        }
        onClose={closeModal}
        frozen={!!redirectUrl}
        testId={TEST_IDS.VERIFICATION_SUCCEEDED}
      />
    );
  }

  return (
    <Modal
      title="Verifying your identity..."
      subtitle="This may take a few seconds. Thanks for your patience!"
      onClose={closeModal}
      frozen={!hasErrors}
      testId={TEST_IDS.VERIFICATION_PENDING}
    />
  );
};

export const TEST_IDS = {
  VERIFICATION_PENDING: 'Modal-pending-verification',
  VERIFICATION_SUCCEEDED: 'Modal-verification-succeeded'
};

export default MagicLinkVerificationModal;
