import { useEffect } from 'react';

import { load as loadBotD } from '@fingerprintjs/botd';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { FINGERPRINT_COOKIE, isTesting } from 'config/constants';
import { logError } from 'helpers/logError';
import useCookie from 'react-use-cookie';
import useCurrentUser from './useCurrentUser';

export const useFingerprint = () => {
  const [idCookie, setIdCookie] = useCookie(FINGERPRINT_COOKIE, '');
  const { user } = useCurrentUser();
  const { isLoading, error, data, getData } = useVisitorData(
    { linkedId: user.id },
    { immediate: false }
  );

  useEffect(() => {
    const fetchAndSaveVisitorID = async () => {
      const { bot } = (await loadBotD()).detect();
      if (bot && !isTesting) {
        return;
      }

      try {
        const dataResult = await getData();
        if (error || !dataResult?.visitorId) {
          logError(error || Error('Failed to save fingerprint data'), {
            metadata: { fingerprint: { dataResult } }
          });
        } else {
          setIdCookie(dataResult.visitorId, {
            SameSite: 'Lax',
            domain: 'protonradio.com'
          });
        }
      } catch (error) {
        logError(error || Error('Failed to save fingerprint data'), {
          metadata: { fingerprint: { data } }
        });
      }
    };

    if (!idCookie && !isLoading && !error) {
      fetchAndSaveVisitorID().catch(logError);
    }
  }, [idCookie, setIdCookie, isLoading, error, data, getData]);

  return idCookie;
};

export default useFingerprint;
