import PropTypes from 'prop-types';
import React from 'react';

import ActionMenu, { ActionMenuHeader, ActionMenuList } from 'components/ActionMenu';
import { DropdownMenuItem, StyledDropdownMenu } from 'components/DropdownMenu';
import MenuToggler from 'components/MenuToggler';
import { generateSelectorsFromTestIds } from 'helpers';
import FeedbackCardHeader from './FeedbackCardHeader';
import { getTrackArtistsToDisplay } from './helpers';

const LINK_ICON = 'icon-link.svg';

/**
 * [FeedbackMenuToggler] - renders action menu or popover for track feedback
 *
 * Expects the following props that are provided by the feedback endpoint:
 * @param {object} track
 * @param {object} user
 * @param {object} artist
 * @param {object} rating
 *
 * Props specific to MenuToggler:
 * @param {function} children - button that will trigger display of menu/dropdown
 * children receives ({ open, close, isOpen })
 * @param {string} [renderDirection] - render direction for dropdown
 */

const FeedbackMenuToggler = ({
  track,
  artist,
  rating,
  user,
  children,
  isTrackPageActive,
  ...rest
}) => {
  const headerProps = { rating, artist, user };

  const trackArtistActions = getTrackArtistsToDisplay(track, artist).map(trackArtist => ({
    name: `View ${trackArtist.name}`,
    icon: LINK_ICON,
    to: `${trackArtist.slug}/tracks`,
    testId: `View-artist-${trackArtist.id}`
  }));

  const actions = [
    {
      // This is the artist reviewing the track
      name: `View ${artist.name}`,
      icon: LINK_ICON,
      to: `${artist.slug}/tracks`,
      testId: FeedbackMenuToggler.TEST_IDS.getTestIdForArtist(artist.id)
    },
    // TODO: API does not return release slug. Likely won't change before this gets replaced with graphql someday,
    // but we used to try to link to the track page here, which never was fully finished.
    // See: GET /promos/reactions/artist/v2/42772/given
    // {
    //   name: 'View Release',
    //   icon: LINK_ICON
    //   to: track.release.slug
    // },
    ...trackArtistActions
  ];

  return (
    <MenuToggler
      renderToggle={openerProps => children(openerProps)}
      renderActionMenu={({ isOpen, close }) => (
        <ActionMenu close={close} isOpen={isOpen}>
          <ActionMenuHeader>
            <FeedbackCardHeader {...headerProps} />
          </ActionMenuHeader>

          <ActionMenuList actions={actions} closeMenu={close} />
        </ActionMenu>
      )}
      renderPopoverContent={() => (
        <StyledDropdownMenu>
          {actions.map(action => (
            <DropdownMenuItem key={action.name} {...action}>
              {action.name}
            </DropdownMenuItem>
          ))}
        </StyledDropdownMenu>
      )}
      {...rest}
    />
  );
};

FeedbackMenuToggler.TEST_IDS = {
  getTestIdForArtist: artistId => `View-artist-${artistId}`
};

FeedbackMenuToggler.SELECTORS = generateSelectorsFromTestIds(
  FeedbackMenuToggler.TEST_IDS
);

FeedbackMenuToggler.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    slug: PropTypes.string,
    version: PropTypes.string
  }),
  artist: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string
  }),
  user: PropTypes.shape(),
  rating: PropTypes.number,
  children: PropTypes.func,
  // redux connect
  isTrackPageActive: PropTypes.bool
};

export default FeedbackMenuToggler;
