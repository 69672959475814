import { FingerprintJSPro, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import queryString from 'query-string';
import React, { ReactNode, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager } from 'styled-components';
import { Provider as UrqlProvider } from 'urql';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { getClient } from 'api/urqlConfig';
import App from 'components/App';
import { appUpdatedMessage } from 'components/App/AppLoader/PushHandler';
import ErrorBoundary from 'components/ErrorBoundary';
import { configAxios } from 'config/axios';
import historyConfig from 'config/history';
import { persistor, store } from 'config/store';
import { CurrentUserProvider } from 'hooks/useCurrentUser';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logoutUser } from 'redux/actions/user';
import { register as serviceWorkerRegister } from './serviceWorkerRegistration';

import { ThemeProvider } from '@protonradio/proton-ui';
import 'config/retinaScreenDetect';
import 'core-js/features/map';
import 'core-js/features/object';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string/replace-all';

// init axios interceptor
configAxios(store);

const container = document.getElementById('root');
const root = createRoot(container!);

function URQLProvider({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const token = useAppSelector(state => state.token.jwt);

  const client = useMemo(() => {
    const logout = () => dispatch(logoutUser());
    return getClient(!!token, logout);
  }, [dispatch, token]);

  return <UrqlProvider value={client}>{children}</UrqlProvider>;
}

root.render(
  <ErrorBoundary>
    <StyleSheetManager enableVendorPrefixes={false}>
      <ThemeProvider theme="light">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <URQLProvider>
              <CurrentUserProvider>
                <Router history={historyConfig}>
                  <QueryParamProvider
                    adapter={ReactRouter5Adapter}
                    options={{
                      searchStringToObject: queryString.parse,
                      objectToSearchString: queryString.stringify
                    }}
                  >
                    <GoogleOAuthProvider
                      clientId={`287898419458-${process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}.apps.googleusercontent.com`}
                    >
                      <FpjsProvider
                        loadOptions={{
                          apiKey: process.env.REACT_APP_FPJS_API_KEY!,
                          endpoint: [
                            'https://metrics.protonradio.com',
                            FingerprintJSPro.defaultEndpoint
                          ],
                          scriptUrlPattern: [
                            'https://metrics.protonradio.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
                            FingerprintJSPro.defaultScriptUrlPattern
                          ]
                        }}
                      >
                        <App />
                      </FpjsProvider>
                    </GoogleOAuthProvider>
                  </QueryParamProvider>
                </Router>
              </CurrentUserProvider>
            </URQLProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyleSheetManager>
  </ErrorBoundary>
);

serviceWorkerRegister({
  onUpdate: registration => {
    // intercept a browser refresh
    window.addEventListener(
      'pagehide',
      event => {
        if (!event.persisted && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING', payload: 'pagehide' });
        }
      },
      { once: true }
    );
    store.dispatch({
      type: 'ALERT_MESSAGE_ADD',
      payload: appUpdatedMessage
    });
  },
  onActivated: () =>
    store.dispatch({
      type: 'ALERT_MESSAGE_REMOVE',
      payload: { id: 'AppUpdatedMessage' }
    })
});
