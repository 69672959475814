import React from 'react';

type TextHeaderType = {
  class: string;
  tag: string;
};

interface TextHeaderProps {
  type?: TextHeaderType;
  children?: React.ReactNode | React.ReactNode[];
  customTag?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: React.CSSProperties;
  color?: string;
  weight?: string;
  title?: string;
  truncate?: boolean;
  uppercase?: boolean;
  colorLinks?: boolean;
  underline?: boolean;
}

const TextHeader = ({
  type,
  children,
  customTag,
  className,
  style,
  color,
  weight = TextHeader.WEIGHTS.HEAVY,
  title,
  truncate,
  uppercase,
  colorLinks,
  underline,
  ...rest
}: TextHeaderProps) => {
  const HeaderTag = (customTag || type?.tag || 'div') as keyof JSX.IntrinsicElements;

  const _classes = [
    'TextHeader',
    `TextHeader--${type?.class}`,
    color,
    weight,
    className,
    truncate && 'TextHeader--ellipsis-overflow',
    uppercase && 'TextHeader--uppercase',
    colorLinks && 'TextHeader--colorLinks',
    underline && 'TextHeader--underline'
  ]
    .filter(Boolean)
    .join(' ');

  const headerTitle =
    truncate && children && typeof children === 'string' ? children : title;

  return (
    <HeaderTag title={headerTitle} className={_classes} style={style} {...rest}>
      {children}
    </HeaderTag>
  );
};

TextHeader.TYPES = {
  XSMALL: {
    class: 'xsmall',
    tag: 'h6' as const
  },
  SMALL: {
    class: 'small',
    tag: 'h5' as const
  },
  MEDIUM: {
    class: 'medium',
    tag: 'h4' as const
  },
  LARGE: {
    class: 'large',
    tag: 'h3' as const
  },
  XLARGE: {
    class: 'xlarge',
    tag: 'h2' as const
  },
  JUMBO: {
    class: 'jumbo',
    tag: 'h1' as const
  }
} as const;

TextHeader.WEIGHTS = {
  LIGHT: 'TextHeader--weight--light',
  NORMAL: 'TextHeader--weight--normal',
  HEAVY: 'TextHeader--weight--heavy'
} as const;

TextHeader.COLORS = {
  LIGHT: 'TextHeader--color--light',
  SECONDARY: 'TextHeader--color--secondary',
  PRIMARY: 'TextHeader--color--primary',
  WHITE: 'TextHeader--color--white'
} as const;

export default TextHeader;
