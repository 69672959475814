import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Scroll from 'react-scroll';

export interface SideNavItemProps {
  to: string;
  isExternal?: boolean;
  offset?: number;
  children: React.ReactNode;
  manualSpy?: boolean;
  handleNavClick?: (to: string) => void;
  activeNav?: string;
  disabled?: boolean;
  scrollSpy?: boolean;
  'data-testid'?: string;
}

const isActive = (linkName: string, activeNav?: string): string => {
  if (linkName === activeNav) return 'active';
  return '';
};

const SideNavItem: React.FC<SideNavItemProps> = ({
  to,
  isExternal,
  offset = -60,
  children,
  manualSpy = false,
  handleNavClick,
  activeNav,
  disabled,
  scrollSpy = false,
  'data-testid': testId
}) => (
  <li
    className={`SideNav__item ${disabled ? 'SideNav__disabled' : ''}`}
    data-testid={testId || `SideNavItem-${to}`}
    data-disabled={disabled}
  >
    {scrollSpy && (
      <Scroll.Link
        activeClass={manualSpy ? '' : 'active'}
        className={`${manualSpy && isActive(to, activeNav)}`}
        duration={500}
        offset={offset}
        spy={!manualSpy}
        hashSpy={!manualSpy}
        smooth
        to={to}
        onClick={() => {
          if (disabled) return;
          if (manualSpy) handleNavClick?.(to);
        }}
      >
        {children}
      </Scroll.Link>
    )}

    {!scrollSpy && !isExternal && (
      <NavLink activeClassName="active" to={to}>
        {children}
      </NavLink>
    )}

    {!scrollSpy && isExternal && (
      <a href={to} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )}
  </li>
);

export default SideNavItem;
