import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ACTIVE_UNDERLINE_WIDTH = 3; // rem

const StyledNavTabLink = styled(NavLink)`
  display: inline-block;

  margin-left: 1rem;
  margin-right: 1rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:only-child {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  transition: all 0.3s ease;

  .NavTab__title {
    position: relative;
  }

  .NavTab__title:after {
    content: '';
    position: absolute;
    bottom: -0.6rem;
    left: 50%;
    width: ${ACTIVE_UNDERLINE_WIDTH}rem;
    height: 0.2rem;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }

  &.active .NavTab__title:after {
    transform: translateX(-50%) scaleX(1);
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledNavTab = styled.div`
  display: flex;
`;

const StyledTitle = styled.div`
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.03em;
`;

const StyledAside = styled.div`
  align-items: center;
  display: flex;
  margin-left: 0.4rem;
`;

interface NavTabProps {
  title: React.ReactNode;
  testId?: string;
  aside?: React.ReactNode;
  path?: string;
  onClick?: () => void;
  exact?: boolean;
}

//  Navigation tab that auto highlights if active tab based on current location. Uses ReactRouter NavLink. Used in TabbedHeader.

const NavTab = ({ title, testId, aside, path, onClick, exact }: NavTabProps) => (
  <StyledNavTabLink
    to={{
      pathname: path,
      state: { noScroll: true }
    }}
    as={path ? NavLink : 'div'}
    onClick={onClick}
    exact={exact}
    activeClassName="active"
    className="NavTab"
    data-test="NavTab-title"
    data-testid={
      testId ||
      `NavTab-${typeof title === 'string' ? title.split(' ').join('-').toLowerCase() : ''}`
    }
  >
    <StyledNavTab>
      <StyledTitle className="NavTab__title">{title}</StyledTitle>
      <StyledAside>{aside}</StyledAside>
    </StyledNavTab>
  </StyledNavTabLink>
);

NavTab.TEST_IDS = {
  getTestIdForTitle: title => `NavTab-${title.split(' ').join('-').toLowerCase()}`
};

export default NavTab;
