import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Screen from 'components/Screen';
import { BREAKPOINTS, COLORS } from 'config/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import useToggle from 'hooks/useToggle';

import Button from 'components/Button';
import Flex from 'components/Flex';
import { CaretIcon } from 'components/Icons';
import TextHeader from 'components/TextHeader';

/*
 * [FormGroupRow] - Wrapper component that renders a title, subtitle, and toggleable content for form
 * settings with sane-ish defaults.
 *
 * @param {string} [title] - Title displayed by default for form section
 * @param {string} [subtitle] - Subtitle displayed by default below title
 * @param {bool} [showSubtitleMobile=false]
 * @param {func} [renderAside] - Render prop that replaces the default Edit/Done buttons
 * @param {func} renderContent - Render prop for content to be displayed when toggle is active
 * @param {string} [editButtonText] - Text to display in the initial "Edit" button to toggle row open
 * @param {string} [doneButtonText] - Text to display in the button to toggle the row closed
 * @param {bool} [editable] - Boolean flag to disable toggling of row open/closed. Used primarily when toggle
 * switches are used in renderAside
 * @param {string} [to] - path that entire row should link to. If passed, row will be uneditable by default. This
 * is especially useful on mobile.
 * @param {func} [onEdit] - Callback function to be called when edit button is clicked
 * @param {func} [onDismiss] - Callback function to be called when done button is clicked
 * @param {bool} [initialEditState] - Determine whether a form should be rendered in the "editable" (i.e. open) position
 * @param {string} [value] - Current main value of the row. Useful for giving a preview of current form values.
 * @param {func({size, color})} [renderIcon] - Render prop for icon.  Will receive default props for icon size & color
 */

const EditingContentWrapper = styled.div`
  margin: 0 1.5rem;
  ${({ $isEditing }) => $isEditing && `margin-bottom: 1.5rem;`}

  @media screen and (min-width: ${BREAKPOINTS.MD}px) {
    padding-left: ${({ $icon }) => $icon && '5.5rem'};
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    margin: 0 1.2rem;
    ${({ $isEditing }) => $isEditing && `margin-bottom: 1.2rem;`}
  }
`;

const StyledListItem = styled.li.attrs(() => ({
  className: 'hairline-border'
}))`
  ${({ $bottomBorder }) =>
    $bottomBorder &&
    `
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: initial;
    }
  `}
  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
  width: 100%;
`;

const CollapsedRow = styled.div`
  display: block;
  padding: 1rem 1.2rem;
  ${({ $clickToExpand, onClick }) =>
    ($clickToExpand || onClick) &&
    `
    cursor: pointer;
  `};
`;

const StyledIcon = styled.div`
  align-items: center;
  background-image: linear-gradient(
    to bottom right,
    rgba(220, 96, 20, 0.1) 0%,
    rgba(220, 96, 20, 0.1) 100%
  );
  border-radius: 6px;
  box-shadow: inset -5px -5px 10px rgba(200, 200, 200, 0.15);
  display: flex;
  flex: 0 0 auto;
  height: 4rem;
  justify-content: center;
  margin-right: 1.5rem;
  position: relative;
  width: 4rem;

  img {
    max-height: 55%;
    max-width: 60%;
  }
`;

const Content = styled.div`
  overflow: hidden;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;

export const FormGroupRow = props => {
  const {
    children,
    title,
    subtitle,
    to,
    showSubtitleMobile,
    renderAside,
    renderContent,
    renderEditingContent,
    renderIcon,
    editButtonText = 'Edit',
    doneButtonText = 'Done',
    onDismiss,
    onEdit,
    onClick,
    editable = true,
    isEditing: isEditingProp,
    initialEditState,
    disabled,
    bottomBorder = true,
    'data-testid': testId,
    value
  } = props;
  const { isOpen, open, close } = useToggle(initialEditState);
  const isEditing = isOpen || isEditingProp;
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const renderEditButton = editable && !renderAside && !to && !onClick;
  const clickToExpand = isSmallScreen && renderEditButton;

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss({ close });
    } else {
      close();
    }
  };

  const rowProps =
    clickToExpand && !to
      ? {
          onClick: () => {
            // Only register full row click on mobile if not currently editing.
            if (isEditing) {
              handleDismiss();
            } else {
              open();
            }
          }
        }
      : {};

  return (
    <StyledListItem
      $bottomBorder={bottomBorder}
      $disabled={disabled}
      data-testid={FormGroupRow.TEST_IDS.CONTAINER}
    >
      {/* NOTE: we put a disabled attribute on the test selector for testing purposes */}
      <div data-testid={testId} disabled={disabled}>
        <CollapsedRow
          $clickToExpand={clickToExpand}
          as={to && !disabled ? Link : 'div'}
          to={to}
          onClick={!disabled ? onClick : undefined}
        >
          {children}
          <Flex align="center" {...rowProps}>
            {renderIcon && (
              <StyledIcon className="text-center">
                {renderIcon({ size: 24, color: COLORS.PRIMARY })}
              </StyledIcon>
            )}

            <Content className="flex__grow pr-2">
              {title && <FormGroupRow.Title>{title}</FormGroupRow.Title>}

              {(subtitle || value) && (
                <FormGroupRow.Subtitle>
                  <Screen.MEDIUM up>
                    <strong>
                      {value && value}
                      {value && subtitle && <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>}
                    </strong>
                    {subtitle}
                  </Screen.MEDIUM>

                  <Screen.MEDIUM down>
                    <strong>
                      {value}
                      {value && subtitle && showSubtitleMobile && (
                        <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>
                      )}
                    </strong>
                    {showSubtitleMobile && subtitle}
                  </Screen.MEDIUM>
                </FormGroupRow.Subtitle>
              )}

              {renderContent && renderContent()}
            </Content>

            {renderEditButton && (
              <>
                <Screen.MEDIUM up>
                  <div className="flex__shrink pl-3">
                    {isEditing ? (
                      <FormGroupRow.Button
                        onClick={handleDismiss}
                        data-testid={FormGroupRow.TEST_IDS.DONE_BUTTON}
                      >
                        {doneButtonText}
                      </FormGroupRow.Button>
                    ) : (
                      <FormGroupRow.Button
                        onClick={onEdit || open}
                        data-testid={FormGroupRow.TEST_IDS.EDIT_BUTTON}
                      >
                        {editButtonText}
                      </FormGroupRow.Button>
                    )}
                  </div>
                </Screen.MEDIUM>
                <Screen.MEDIUM down>
                  {isEditing ? (
                    <CaretIcon size={12} direction={CaretIcon.DIRECTIONS.UP} />
                  ) : (
                    <CaretIcon
                      size={12}
                      direction={
                        to ? CaretIcon.DIRECTIONS.RIGHT : CaretIcon.DIRECTIONS.DOWN
                      }
                    />
                  )}
                </Screen.MEDIUM>
              </>
            )}

            {(to || onClick) && (
              <CaretIcon size={12} direction={CaretIcon.DIRECTIONS.RIGHT} />
            )}

            {renderAside && <div className="flex__shrink">{renderAside()}</div>}
          </Flex>
        </CollapsedRow>

        {renderEditingContent && editable && (
          <EditingContentWrapper $icon={!!renderIcon} $isEditing={isEditing}>
            {renderEditingContent({ isEditing, open, close })}
          </EditingContentWrapper>
        )}
      </div>
    </StyledListItem>
  );
};

FormGroupRow.Button = ({ children, ...rest }) => (
  <Button color={Button.COLORS.NEUTRAL} size={Button.SIZES.SMALL} {...rest}>
    {children}
  </Button>
);

FormGroupRow.Title = ({ children, ...rest }) => (
  <TextHeader
    type={TextHeader.TYPES.MEDIUM}
    weight={TextHeader.WEIGHTS.HEAVY}
    className="mb-0"
    data-testid={FormGroupRow.TEST_IDS.TITLE}
    {...rest}
  >
    {children}
  </TextHeader>
);

FormGroupRow.Subtitle = ({ children, ...rest }) => (
  <TextHeader
    type={TextHeader.TYPES.SMALL}
    weight={TextHeader.WEIGHTS.LIGHT}
    color={TextHeader.COLORS.SECONDARY}
    className="mb-0"
    data-testid={FormGroupRow.TEST_IDS.SUBTITLE}
    {...rest}
  >
    {children}
  </TextHeader>
);
FormGroupRow.TEST_IDS = {
  CONTAINER: 'FormGroupRow-container',
  TITLE: 'FormGroupRow-title',
  SUBTITLE: 'FormGroupRow-subtitle',
  DONE_BUTTON: 'FormGroupRow-done-button',
  EDIT_BUTTON: 'FormGroupRow-edit-button'
};

export const commonPropTypeDefinition = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  renderIcon: PropTypes.func,
  'data-testid': PropTypes.string
};

FormGroupRow.propTypes = {
  ...commonPropTypeDefinition,
  renderAside: PropTypes.func,
  renderContent: PropTypes.func,
  showSubtitleMobile: PropTypes.bool,
  isEditing: PropTypes.bool,
  disabled: PropTypes.bool,
  bottomBorder: PropTypes.bool,
  editButtonText: PropTypes.string,
  doneButtonText: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.node,
  renderEditingContent: PropTypes.func,
  onClick: PropTypes.func,
  onDismiss: PropTypes.func,
  onEdit: PropTypes.func,
  initialEditState: PropTypes.bool,
  to: PropTypes.string
};

export default FormGroupRow;
