import React, { useMemo } from 'react';

import Button from 'components/Button';
import { LAST_POLICY_CONSENT_STORAGE_KEY, POLICY_LAST_UPDATED } from 'config/constants';
import { isMobile } from 'helpers/screen';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import useCurrentUser from 'hooks/useCurrentUser';
import { PrivacyPolicyConsentMutation } from 'hooks/useInitializeSideEffects/usePrivacyPolicyConsent';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../Alert';

const PRIVACY_POLICY_VERSION = process.env.REACT_APP_PRIVACY_POLICY_VERSION!;

const StackedAlert = styled.div`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  row-gap: '0.25rem';
  margin-bottom: '0.25rem';
  width: '100%';
`;

const PrivacyPolicyUpdated = ({
  handleConsent,
  fetching
}: {
  handleConsent: () => void;
  fetching: boolean;
}) => {
  const { pathname, search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  const toggleChangelog = () => {
    if (params.has('changelog')) {
      params.delete('changelog');
    } else {
      params.append('changelog', 'display');
    }
    history.push({ pathname, search: params.toString() });
  };

  return (
    <StackedAlert>
      <Alert.Content data-testid="PolicyUpdatedMessage" style={{ marginBottom: '1rem' }}>
        {`By using this site you agree to our privacy policy (Last updated: ${POLICY_LAST_UPDATED.toLocaleDateString(
          undefined,
          { year: 'numeric', month: 'long', day: 'numeric' }
        )})`}
      </Alert.Content>

      <Alert.ButtonGroup style={{ margin: '0', width: '100%' }}>
        {!isMobile() && !pathname.includes('/privacy-policy') && (
          <Button
            onClick={toggleChangelog}
            color={Button.COLORS.NEUTRAL}
            data-testid="ChangelogModalButton"
          >
            View Recent Update
          </Button>
        )}

        {!pathname.includes('/privacy-policy') && (
          <Button to="/privacy-policy" color={Button.COLORS.NEUTRAL}>
            View Policy
          </Button>
        )}

        <Button disabled={fetching} onClick={handleConsent}>
          Accept Policy
        </Button>
      </Alert.ButtonGroup>
    </StackedAlert>
  );
};

export const PrivacyPolicyConsent = ({ dismissAlert }: { dismissAlert: () => void }) => {
  const [{ fetching }, consentToPrivacyPolicy] = useMutationWithAlert(
    PrivacyPolicyConsentMutation
  );
  const { user } = useCurrentUser();

  const handleConsent = async () => {
    window.localStorage.setItem(LAST_POLICY_CONSENT_STORAGE_KEY, PRIVACY_POLICY_VERSION);

    if (user.id) {
      await consentToPrivacyPolicy({ version: PRIVACY_POLICY_VERSION });
    }

    dismissAlert();
  };

  if (user.id) {
    return (
      <PrivacyPolicyUpdated
        handleConsent={() => void handleConsent()}
        fetching={fetching}
      />
    );
  }

  return (
    <>
      <Alert.Content data-testid="PrivacyPolicyConsent">
        By using this site you agree to our privacy policy.
      </Alert.Content>

      <Alert.ButtonGroup>
        <Button to="/privacy-policy" color={Button.COLORS.NEUTRAL}>
          View
        </Button>

        <Button disabled={fetching} onClick={handleConsent}>
          Accept Policy
        </Button>
      </Alert.ButtonGroup>
    </>
  );
};
