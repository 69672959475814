import { lazy } from 'react';

export const Radio = lazy(
  () => import('../../routes/Radio' /* webpackChunkName: "radio" */)
);

export const Account = lazy(
  () => import('../../routes/Account' /* webpackChunkName: "account" */)
);

export const ProUserAccountVerification = lazy(
  () =>
    import(
      '../../routes/ProUserAccountVerification' /* webpackChunkName: "pro-user-account-verification" */
    )
);

export const SearchResults = lazy(
  () => import('../../routes/SearchResults' /* webpackChunkName: "search-results" */)
);

export const ExploreMixes = lazy(
  () => import('../../routes/ExploreMixes' /* webpackChunkName: "explore-mixes" */)
);

export const MixesBrowse = lazy(
  () => import('../../routes/MixesBrowse' /* webpackChunkName: "mixes-browse" */)
);

export const TracksBrowse = lazy(
  () => import('../../routes/TracksBrowse' /* webpackChunkName: "tracks-browse" */)
);

export const Top100Mixes = lazy(
  () => import('../../routes/Top100Mixes' /* webpackChunkName: "top-100-mixes" */)
);

export const ArtistBrowse = lazy(
  () => import('../../routes/ArtistBrowse' /* webpackChunkName: "artist-browse" */)
);

export const GenreBrowse = lazy(
  () => import('../../routes/GenreBrowse' /* webpackChunkName: "genre-browse" */)
);

export const ShowBrowse = lazy(
  () => import('../../routes/ShowBrowse' /* webpackChunkName: "show-browse" */)
);

export const Label = lazy(
  () => import('../../routes/Label' /* webpackChunkName: "label" */)
);

export const SignOut = lazy(
  () => import('../../routes/SignOut' /* webpackChunkName: "sign-out" */)
);

export const ErrorPage = lazy(
  () => import('../../routes/ErrorPage' /* webpackChunkName: "error-page" */)
);

export const ConfirmationPage = lazy(
  () =>
    import('../../routes/ConfirmationPage' /* webpackChunkName: "confirmation-page" */)
);

export const AddSubscriber = lazy(
  () =>
    import(
      '../../routes/Label/LabelSubscribers/AddSubscriber' /* webpackChunkName: "add-subscriber" */
    )
);

export const Release = lazy(
  () => import('../../routes/Release' /* webpackChunkName: "release" */)
);

export const Inbox = lazy(
  () => import('../../routes/Inbox' /* webpackChunkName: "inbox" */)
);

export const InboxWelcome = lazy(
  () => import('../../routes/Inbox/Welcome' /* webpackChunkName: "inbox-welcome" */)
);

export const TrackStack = lazy(
  () => import('../../routes/TrackStack' /* webpackChunkName: "track-stack" */)
);

export const ArtistProfileWizard = lazy(
  () =>
    import(
      '../../routes/ArtistProfileWizard' /* webpackChunkName: "artist-profile-wizard" */
    )
);

export const PromoEarlyAccess = lazy(
  () =>
    import('../../routes/PromoEarlyAccess' /* webpackChunkName: "promo-early-access" */)
);

export const ReleaseContractConfirmation = lazy(
  () =>
    import(
      '../../routes/Contract/ReleaseContractConfirmation' /* webpackChunkName: "release-contract-confirmation" */
    )
);

export const YoutubeUploader = lazy(
  () => import('../../routes/YoutubeUploader' /* webpackChunkName: "youtube-uploader" */)
);

export const ProtonRadio = lazy(
  () => import('../../routes/ProtonRadio' /* webpackChunkName: "proton-radio" */)
);

export const DiscoveryModeDashboard = lazy(
  () => import('../../routes/DiscoveryMode' /* webpackChunkName: "discovery-mode" */)
);
