import React, { useCallback, useState } from 'react';

import DropdownMenu from 'components/DropdownMenu';

import { getTrackActions } from 'helpers';

import { ReleaseDspQuery } from 'graphql/queries/release';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';
import { AlgoliaTrack } from 'types';
import { useQuery } from 'urql';

type Props = {
  track: AlgoliaTrack;
  setDropdownIndex?: (index: number | null) => void;
  rowIndex: number;
};

const TracksTableDropdown = ({
  track,
  setDropdownIndex = () => null,
  rowIndex
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => {
    setDropdownIndex(rowIndex);
    setIsOpen(true);
  }, [rowIndex, setDropdownIndex]);
  const onClose = useCallback(() => {
    setDropdownIndex(null);
    setIsOpen(false);
  }, [setDropdownIndex]);

  const [{ data }] = useQuery({
    query: ReleaseDspQuery,
    variables: { id: String(track.release.id) },
    pause: !track.release.id || !isOpen
  });
  const dspLinks = data ? releaseStoreInfoButtonsTransform(data) : null;
  const dropdownActions = getTrackActions({
    track,
    dspLinks
  });

  return <DropdownMenu onOpen={onOpen} onClose={onClose} actions={dropdownActions} />;
};

export default TracksTableDropdown;
