import _kebabCase from 'lodash/kebabCase';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollSpy as reactScrollSpy, scroller } from 'react-scroll';
import styled from 'styled-components';

import SideNavItem, { SideNavItemProps } from './SideNavItem';

const IconWrapper = styled.div`
  margin-right: 0.75rem;
  max-height: 2rem;
  min-width: 1.8rem;
  text-align: center;
`;

type Scroller = {
  scrollTo: (
    to: string,
    options?: { duration: number; delay: number; smooth: boolean }
  ) => void;
  update: () => void;
};

export type SideNavRoute = {
  name: string;
  to: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isHidden?: boolean;
  isDisabled?: boolean;
  isExternal?: boolean;
};

interface SideNavProps {
  children?: React.ReactNode;
  routes?: SideNavRoute[];
  scrollSpy?: boolean;
}

const SideNav: React.FC<SideNavProps> = ({ children, routes, scrollSpy = false }) => {
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (!scrollSpy) return;

    const duration = 250;
    const hash = location.hash.substr(1);
    const delay = 100;

    // handle scrolling to hash if included when navigating to page
    if (hash) {
      (scroller as Scroller).scrollTo(hash, {
        duration,
        delay,
        smooth: true
      });

      // scroll to hash and update scroll spy
      setTimeout(
        () => {
          (reactScrollSpy as Scroller).update();
        },
        duration + delay + 500
      );
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash, scrollSpy]);

  return (
    <ul className="SideNav__container">
      {children
        ? React.Children.map(children, child =>
            React.isValidElement(child)
              ? React.cloneElement(child as React.ReactElement<SideNavItemProps>, {
                  activeNav: activeNav || undefined,
                  handleNavClick: (to: string) => setActiveNav(to)
                })
              : child
          )
        : routes?.map(route => {
            if (route.isHidden) return null;

            return (
              <SideNavItem
                key={route.name}
                to={route.to}
                isExternal={route.isExternal}
                disabled={route.isDisabled}
                scrollSpy={scrollSpy}
                data-testid={getNavItemTestId(route.name)}
              >
                {route.icon && (
                  <IconWrapper>
                    <route.icon width={18} color="currentColor" />
                  </IconWrapper>
                )}
                {route.name}
              </SideNavItem>
            );
          })}
    </ul>
  );
};

export const getNavItemTestId = (name: string) => `SideNavItem-${_kebabCase(name)}`;

export default SideNav;
