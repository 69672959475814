import PropTypes from 'prop-types';
import { DIGITAL_SERVICE_PROVIDERS } from './index';

const ENTITY_PROPTYPES = {
  TRACK: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    artists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
        image_url: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            order: PropTypes.number
          })
        )
      })
    )
  }),
  ARTIST_PROFILE: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    verified: PropTypes.bool,
    soundcloud_url: PropTypes.string,
    image_type: PropTypes.string,
    image_url: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    tracks_count: PropTypes.number,
    mixes_count: PropTypes.number,
    active_start_year: PropTypes.number,
    active_end_year: PropTypes.number,
    releases_on: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        release_count: PropTypes.number,
        slug: PropTypes.string
      })
    ),
    bio: PropTypes.string,
    has_image: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        pro_email: PropTypes.string,
        country: PropTypes.string
      })
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    shows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    connections: PropTypes.arrayOf(
      PropTypes.oneOfType([
        // SOUNDCLOUD
        PropTypes.shape({
          avatar_url: PropTypes.string,
          id: PropTypes.number, // proton specific connection id
          dsp: PropTypes.oneOf(['soundcloud']),
          dsp_name: PropTypes.oneOf(['SoundCloud']),
          followers_count: PropTypes.number,
          dsp_id: PropTypes.number, // i.e. 1770591
          dsp_url: PropTypes.string, // i.e. "http://www.soundcloud.com/charlesnavi"
          value: PropTypes.string, // i.e. "charlesnavi"
          verified: PropTypes.bool,
          subscription: PropTypes.string // i.e. "free"
        }),
        // SPOTIFY
        PropTypes.shape({
          id: PropTypes.number, // proton specific connection id
          dsp: PropTypes.oneOf(['spotify']),
          dsp_name: PropTypes.oneOf(['Spotify']),
          followers_count: PropTypes.number,
          dsp_id: PropTypes.string, // i.e. "4NvdErVGNl5coh9ID6XB6q"
          dsp_url: PropTypes.string, // i.e. "https://open.spotify.com/artist/4NvdErVGNl5coh9ID6XB6q"
          value: PropTypes.string, // i.e. "Charles Navi"
          verified: PropTypes.bool,
          // currently null?
          avatar_url: PropTypes.string,
          avatar_width: PropTypes.number,
          avatar_height: PropTypes.number
        }),
        // APPLE MUSIC
        PropTypes.shape({
          id: PropTypes.number,
          dsp: PropTypes.oneOf(['apple-music']),
          dsp_name: PropTypes.oneOf(['Apple Music / iTunes']),
          followers_count: PropTypes.number,
          dsp_id: PropTypes.string, // i.e. '621560977'
          dsp_url: PropTypes.string, // i.e. "https://music.apple.com/artist/charles-navi/621560977"
          value: PropTypes.string, // i.e. "Charles Navi"
          // currently null?
          avatar_url: PropTypes.string,
          avatar_width: PropTypes.number,
          avatar_height: PropTypes.number
        })
      ])
    )
  }),
  // Algolia artist:
  ARTIST: PropTypes.shape({
    id: PropTypes.number,
    connections: PropTypes.arrayOf(PropTypes.shape()), // NOTE: pending field
    name: PropTypes.string,
    slug: PropTypes.string,
    verified: PropTypes.bool,
    soundcloud_url: PropTypes.string,
    image_type: PropTypes.string,
    image_url: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    tracks_count: PropTypes.number,
    mixes_count: PropTypes.number,
    active_start_year: PropTypes.number,
    active_end_year: PropTypes.number,
    releases_on: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        release_count: PropTypes.number,
        slug: PropTypes.string
      })
    ),
    artist_configs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        artist_id: PropTypes.number,
        config_key: PropTypes.string,
        config_value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number
        ])
      })
    ),
    bio: PropTypes.string,
    has_image: PropTypes.bool
  }),
  USER: PropTypes.shape({
    account_type: PropTypes.string,
    user_id: PropTypes.number,
    username: PropTypes.string,
    user_from: PropTypes.string,
    user_email: PropTypes.string,
    user_website: PropTypes.string,
    delete_at: PropTypes.string,
    subscriber: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      subscription: PropTypes.string
    }),
    authentication_token: PropTypes.string,
    google_oauth: PropTypes.bool,
    google_identity: PropTypes.shape({
      iss: PropTypes.string,
      azp: PropTypes.string,
      aud: PropTypes.string,
      sub: PropTypes.string,
      email: PropTypes.string,
      email_verified: PropTypes.bool,
      at_hash: PropTypes.string,
      name: PropTypes.string,
      picture: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
      locale: PropTypes.string,
      iat: PropTypes.number,
      exp: PropTypes.number
    }),
    privacy_policy_consents: PropTypes.arrayOf(
      PropTypes.shape({
        version: PropTypes.string,
        timestamp: PropTypes.number
      })
    ),
    'has_promo_access?': PropTypes.bool,
    proton_newsletter_subscription: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profiles: PropTypes.arrayOf(
      PropTypes.oneOfType([
        // ARTIST
        PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
          image_type: PropTypes.string,
          image_url: PropTypes.string,
          has_image: PropTypes.bool,
          releases_on: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
              release_count: PropTypes.number,
              slug: PropTypes.string
            })
          )
        }),
        // LABEL
        PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
          label_slug: PropTypes.string,
          beatport_id: PropTypes.number
        }),
        // SHOW
        PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
          name: PropTypes.string
        })
      ])
    ),
    email: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    paypal_email: PropTypes.string,
    promo_email: PropTypes.string,
    accesslevel: PropTypes.number,
    soundsystem_newsletter_subscription: PropTypes.bool,
    soundsystem_demos_subscription: PropTypes.bool,
    user_settings: PropTypes.arrayOf(
      PropTypes.shape({
        setting: PropTypes.string,
        enabled: PropTypes.bool,
        value: PropTypes.string
      })
    ),
    soundcloud_auths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        phpbb2_user_id: PropTypes.number,
        soundcloud_profile_id: PropTypes.number
      })
    ),
    soundcloud_profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        dsp: PropTypes.string,
        dsp_name: PropTypes.string,
        username: PropTypes.string,
        avatar_url: PropTypes.string,
        soundcloud_url: PropTypes.string,
        followers_count: PropTypes.number,
        // subscription: null,
        verified: PropTypes.bool,
        value: PropTypes.string,
        soundcloud_id: PropTypes.number,
        connection_name: PropTypes.string,
        dsp_id: PropTypes.number,
        dsp_url: PropTypes.string
      })
    ),
    spotify_auth: PropTypes.shape({
      id: PropTypes.number,
      phpbb2_user_id: PropTypes.number,
      spotify_id: PropTypes.string,
      display_name: PropTypes.string,
      email: PropTypes.string
    }),
    dropbox_oauth: PropTypes.shape({
      uid: PropTypes.number,
      profile: PropTypes.shape({
        account_id: PropTypes.string,
        name: PropTypes.shape({
          given_name: PropTypes.string,
          surname: PropTypes.string,
          familiar_name: PropTypes.string,
          display_name: PropTypes.string,
          abbreviated_name: PropTypes.string
        }),
        email: PropTypes.string,
        email_verified: PropTypes.bool,
        disabled: PropTypes.bool,
        is_teammate: PropTypes.bool
      })
    })
  }),
  PRO_USER: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pro_email: PropTypes.string,
    country: PropTypes.string,
    releases_on: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        release_count: PropTypes.number,
        slug: PropTypes.string
      })
    ),
    verified: PropTypes.bool,
    username: PropTypes.string,
    address: PropTypes.string,
    promo_email: PropTypes.string,
    paypal_email: PropTypes.string,
    details_confirmed: PropTypes.bool,
    __current_user_can_edit: PropTypes.bool,
    artists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
        image_type: PropTypes.string,
        image_url: PropTypes.string,
        has_image: PropTypes.bool,
        verified: PropTypes.bool
      })
    )
  }),
  DSP_TYPES: PropTypes.oneOf(Object.values(DIGITAL_SERVICE_PROVIDERS)),
  WIZARD_TRANSACTION: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape(),
    userId: PropTypes.number,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    lastUpdated: PropTypes.string
  })
};

export default ENTITY_PROPTYPES;
