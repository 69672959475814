import _kebabCase from 'lodash/kebabCase';
import React from 'react';

import FormGroup from 'components/Form/FormGroup';
import { SideNavRoute } from '../SideNav/SideNav';

interface MobileNavProps {
  routes: SideNavRoute[];
  className?: string;
}

const MobileNav: React.FC<MobileNavProps> = ({ routes, className }) => (
  <FormGroup className={className}>
    <FormGroup.List className="pt-0 pb-0">
      {routes.map(({ name, icon: Icon, to, isHidden, isDisabled }) => {
        if (isHidden) return null;

        return (
          <FormGroup.Row
            key={name}
            title={name}
            renderIcon={Icon ? props => <Icon {...props} /> : undefined}
            to={to}
            disabled={isDisabled}
            data-testid={getMobileNavRowTestId(name)}
          />
        );
      })}
    </FormGroup.List>
  </FormGroup>
);

export const getMobileNavRowTestId = (name: string) =>
  `MobileNav-row-${_kebabCase(name)}`;

export default MobileNav;
