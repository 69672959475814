// generic user interface related action
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AlertType, CustomAlertName } from 'components/Alert/Alert';
import { AppDispatch } from 'config/store';
import { formatError } from 'helpers/utilities';
import { CombinedErrorDisplay } from 'hooks/graphql/utils';
import { ReactNode } from 'react';
import { UiState } from 'redux/reducers/ui';
import { RequireKey } from 'types';

// Generic redux action creator that will set reducer by key value (payload should be an object)
export const setUi = createAction<{ [K in keyof UiState]?: UiState[K] }>('UI_SET');

export const toggleOverlay = createAction<boolean>('UI_OVERLAY_TOGGLE');
export const setTouchDevice = createAction('TOUCH_DEVICE_SET');
export const incrementErrorCount = createAction('UI_ACTION_ERROR_COUNT_INCREMENT');
export const resetErrorCount = createAction('UI_ACTION_ERROR_COUNT_RESET');
export const updateWindowAutoScroller = createAction('UI_WINDOW_AUTO_SCROLLER_UPDATE');

// Alerts
// REFERENCE: components/Alert.js

export interface AlertOptions {
  message?: string | ReactNode;
  error?: string | ProtonError;
  component?: CustomAlertName;
  componentProps?: unknown; // refer to individual custom alerts for their required props
  fallbackMessage?: string;
  forceMessage?: string;
  type?: AlertType;
  timeout?: number | boolean;
  dismissable?: boolean;
  dismissText?: string;
  id?: string;
  persistAfterLogout?: boolean;
  actions?: {
    text: string;
    onClick: () => void;
  }[];
}

type ProtonError = (Error | AxiosError) & { __SILENCE__?: boolean; __CANCEL__?: boolean };
type AlertPayload = string | ProtonError | CombinedErrorDisplay | AlertOptions;

const isAlertOptions = (o: object): o is AlertOptions => {
  return !!('message' in o || 'error' in o || 'component' in o);
};
const isAlertPayload = (e: unknown): e is AlertPayload => {
  return !!(
    e &&
    (e instanceof String ||
      e instanceof Error ||
      (typeof e === 'object' && isAlertOptions(e)))
  );
};

type MessageOptions =
  | RequireKey<AlertOptions, 'message'>
  | RequireKey<AlertOptions, 'component'>
  | string;
type DerivedOptions = Exclude<MessageOptions, string> | { silence: true };

export const addAlertMessage = createAction<MessageOptions>('ALERT_MESSAGE_ADD');
export const removeAlertMessage = createAction<{ id?: string | number; all?: boolean }>(
  'ALERT_MESSAGE_REMOVE'
);

const optionsFromPayload = (payload: AlertPayload): DerivedOptions => {
  if (typeof payload === 'string') {
    return {
      type: 'message',
      message: payload
    };
  }

  if (!(payload instanceof Error) && 'component' in payload && payload.component)
    return payload as RequireKey<AlertOptions, 'component'>;

  if ('networkError' in payload || 'graphQLErrors' in payload)
    return {
      type: 'warning',
      message: payload.message
    } as RequireKey<AlertOptions, 'message'>;

  if (payload.message && !(payload instanceof Error)) {
    return {
      type: 'error' in payload ? 'warning' : 'message',
      ...(payload as RequireKey<AlertOptions, 'message'>)
    };
  }
  // **** types of errors to be handled ****
  // Example 1 - An Error instance, or object with an 'error' property with a string or Error value:
  //   new Error('Nope!') OR { error: "Nope!" } OR { error: new Error('Nope!) }
  // Example 2 - A canceled axios error:
  //   { error: { message: "This request was canceled", __CANCEL__: true } }
  // Example 3 - in some cases we have listners set up to handle special errors and once handled we throw an error
  // with the __SILENCE__ attribute as an indication that this error doesn't need to be displayed to the user.
  //   { error: { message: "This request was canceled", __SILENCE__: true } }
  // Example 4 - the rails REST api (v2) might return field specific errors in an object, so ignore in this situation:
  //   {
  //     "success": false,
  //     "error": {
  //       "user_email": [
  //         "has already been taken"
  //       ]
  //     },
  //     "message": "Validation failed: User email has already been taken"
  //   }
  // Example 5: Rails REST api (v2)
  //   {
  //     "success": false,
  //     "message": "SoundCloud profile is already connected to another artist"
  //   }
  if (
    'error' in payload &&
    !(payload instanceof Error) &&
    !(typeof payload.error === 'string') &&
    (payload.error?.__SILENCE__ || payload?.error?.__CANCEL__)
  ) {
    return { silence: true };
  }

  const error = payload instanceof Error ? payload : payload.error;

  return {
    ...payload,
    type: 'warning',
    ...formatError(error, (payload as AlertOptions).fallbackMessage)
  };
};

/**
 * Handle display of alert component by storing an alert message in redux. Passing an 'error' argument will handle
 * formatting for a variety of error formats.
 *
 * __Option 1__ Just pass the error or a string.
 * >```
 * >  showAlert(e)
 * >  showAlert('Hello there!')
 * >```
 *
 * __Option 2__ Pass a payload object (see {@link AlertOptions} type for further configurations)
 * >```
 * >  showAlert({ error: e })
 * >  showAlert({ message: 'Hello there!' })
 * >```
 */
export const showAlert = (alertInput: unknown) => (dispatch: AppDispatch) => {
  if (!alertInput) {
    console.error('Alert passed no payload.');
    return;
  }

  const payload = isAlertPayload(alertInput) ? alertInput : String(alertInput);
  const options = optionsFromPayload(payload);

  if ('silence' in options) return;

  dispatch(addAlertMessage({ ...options }));
};
